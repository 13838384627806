// import request from '@/utils/request'

import team from '@/data/team.json'
import biocubateManagementTeam from '@/data/team_biocubate_management.json'
import sciencesTeam from '@/data/team_sciences.json'
import unifortuneFundTeam from '@/data/team_unifortune_fund.json'

// const team = process.env.VUE_APP_TEAM

export function getTeam (extra = {}) {
  // return request.get('/teams', {
  //   params: {
  //     team_eq: team,
  //     _sort: 'sort:ASC',
  //     ...extra
  //   }
  // })
  if (extra.group) {
    return team.filter(item => item.group === extra.group).slice(0, 5)
  }
  return team
}

export function getSciencesTeam () {
  // return request.get('/teams', {
  //   params: {
  //     team_eq: team,
  //     group: 'sciences',
  //     _sort: 'sort:ASC'
  //   }
  // })
  return sciencesTeam
}

export function getUnifortuneFundTeam () {
  // return request.get('/teams', {
  //   params: {
  //     team_eq: team,
  //     group: 'fund',
  //     _sort: 'sort:ASC'
  //   }
  // })
  return unifortuneFundTeam
}

export function getBiocubateManagementTeam () {
  // return request.get('/teams?id_in=8&id_in=10', {
  //   params: {
  //     _locale: 'en'
  //   },
  //   ignoreLocale: true
  // })
  return biocubateManagementTeam
}
